import React from "react"
import {apiConstants} from "../../constants/ApiConstants";
import dateFormat from "dateformat";
import {headerSortingClasses, sortCaret} from "../../widgets/TableSortingHelpers";
import {SignMosExecutionContainer} from "../../components/containers/SignButtonContainers";
import FilteredTable from "../../components/containers/FilteredTable";

export function MosExecutions() {

  let filterView = (state, activeState, handler) => {
    const statusClass = classByStatus(state.State);
    return (
      <button type="button" className={"btn btn-light"+(activeState === state.State ? ' active' : '')} onClick={() => handler(state.State)}>
        {state.State} <span className={`badge ${statusClass}`}>{state.Count}</span>
      </button>
    );
  }

  const columns = [
    {
      dataField: "SettingsID",
      text: "Аккаунт",
      headerClasses: "text-nowrap",
      classes: "text-nowrap",
    },
    {
      dataField: "CreateDate",
      text: "Дата",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      classes: "text-nowrap",
      formatter: function (cell, row) {
        return dateFormat(row.CreateDate, "HH:MM dd.mm.yy");
      },
    },
    {
      dataField: "RegNum",
      text: "Исполнение",
      sort: false,
      formatter: function (cell, row) {
        return (<a href={"https://zakupki.mos.ru/profile/contractexecution/"+row.RegNum} target="_blank">{row.RegNum}</a>);
      },
    },
    {
      dataField: "Name",
      text: "Контракт",
      sort: false,
      formatter: function (cell, row) {
        return (<a href={"https://old.zakupki.mos.ru/#/contracts/my/"+row.ContractRegNum} target="_blank">{row.Name}</a>);
      },
    },
    {
      dataField: "SubStatus",
      text: "СубСтатус",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "Status",
      text: "Статус",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      classes: "text-nowrap",
      formatter: function (cell, row) {
        const statusClass = classByStatus(row.Status);

        return (
          <span className={"state-4 badge " + statusClass}>
              {row.Status}
          </span>
        );
      },
    },
    {
      dataField: "action",
      text: "Действия",
      formatter: function (cell, row) {
        return ((row.Status === "Подписание поставщиком" || row.Status === "Отказано заказчиком" || row.Status === "Квиток получен") ? <SignMosExecutionContainer id={row.RegNum} /> : '')
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    }
  ];

  return (
    <FilteredTable
      sortField={"CreateDate"}
      dataUrl={apiConstants.API_URL + '/execution/list'}
      filterView={filterView}
      columns={columns}
      title={"УПД на Mos.ru"}
    />
  );
}

function classByStatus(name) {
  let statusClass = 'badge-primary';

  // eslint-disable-next-line default-case
  switch (name) {
    case "Ввод сведений":
      statusClass = "badge-primary";
      break;
    case "Отказано заказчиком":
      statusClass = "badge-danger";
      break;
    case "Подписание поставщиком":
      statusClass = "badge-warning";
      break;
    case "Ожидание заказчика":
      statusClass = "badge-secondary";
      break;
    case "Подписано заказчиком":
      statusClass = "badge-success";
      break;
  }

  return statusClass;
}