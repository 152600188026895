import React from "react"
import {TaskStatus} from "../../widgets/TaskStatus";
import {headerSortingClasses, sortCaret} from "../../widgets/TableSortingHelpers";
import dateFormat from "dateformat";
import FilteredTable from "../../components/containers/FilteredTable";
import {apiConstants} from "../../constants/ApiConstants";

export default function MosCompetitors() {

  let filterView = () => {
    return '';
  }

  const columns = [
    {
      dataField: "fields",
      text: "Название",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: function (cell, row) {
        return (cell.name);
      },
    },
    {
      dataField: "won",
      text: "Выиграл",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "lost",
      text: "Проиграл",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "total",
      text: "Всего",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "first",
      text: "Замечен первый раз",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: function (cell, row) {
        return dateFormat(cell, "HH:MM dd.mm.yy");
      },
    },
    {
      dataField: "last",
      text: "Замечен последний раз",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: function (cell, row) {
        return dateFormat(cell, "HH:MM dd.mm.yy");
      },
    }
  ];

  return (
    <>
      <FilteredTable
        noupdate={true}
        sortField={"won"}
        dataUrl={apiConstants.API_URL + '/mos/competitors'}
        filterView={filterView}
        columns={columns}
        title={"Конкуренты на Mos.ru"}
      />
    </>
  );
}