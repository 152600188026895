import Axios from "axios";
import {apiConstants} from "../constants/ApiConstants";

export const stopwordService = {
    token: localStorage.getItem('token'),
    remove,
    add,
    get,
    update
};

function remove(id) {
    const config = {
        headers: {
            'Authorization':  "bearer " + this.token
        }
    };

    return Axios.delete(apiConstants.API_URL + '/stopwords/' + id, config)
        .then((response) => {
            return response;
        })
}

function add(params) {
    const config = {
        headers: {
            'Authorization':  "bearer " + this.token,
            'Content-Type': 'application/json'
        }
    };

    return Axios.post(apiConstants.API_URL + '/stopwords', JSON.stringify(params), config)
        .then((response) => {
            return response;
        })
}

function get(id, handler) {
    const config = {
        headers: {
            'Authorization': 'bearer ' + this.token
        }
    };

    Axios.get(apiConstants.API_URL + '/stopwords/' + id, config)
        .then(response => {
            if (response.status === 200) {
                const result = response.data;
                if (result.status === 'success') {
                    const data = result.data;
                    handler(data)
                }
            }
        })
}

function update(id, params) {
    const config = {
        headers: {
            'Authorization': 'bearer ' + this.token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };

    return Axios.put(apiConstants.API_URL + '/stopwords/' + id, params, config)
        .then((response) => {
            return response;
        })
}
