import React from "react"
import {routeConstants} from "../../constants/RouteConstants";
import {apiConstants} from "../../constants/ApiConstants";
import {stopwordService} from "../../services/StopwordServices";
import Table from "../../components/containers/Table";

export function Stopwords() {
  const columns = [
    {
      dataField: "id",
      text: "Стоп слово",
      headerClasses: "text-nowrap",
      formatter: function (cell, row) {
        return row.stopword
      },
    },
    {
      dataField: "user",
      text: "Пользователь",
    },
    {
      dataField: "user",
      text: "Пользователь",
      classes: "action-bar",
      formatter: function (cell, row) {
        return (
          <div className="btn-group btn-group-sm action-group" role="group">
            <button onClick={() => {handleDelete(row.id)}} type="button" className="btn btn-danger delete" title="Удалить">
              <i className="fa fa-trash" />
            </button>
          </div>
        )
      }
    }
  ];

  const handleDelete = (id) => {
    stopwordService.remove(id).then(() => {});
  }

  return (
    <Table
      sortField={"name"}
      dataUrl={apiConstants.API_URL + '/stopwords'}
      columns={columns}
      title={"Стоп слова"}
      addLink={routeConstants.PARSER_STOPWORD_ADD}
    />
  );
}