import {formConstants} from "../constants/FormConstants";

export let formReducer = function(state, action) {
    if (state === undefined) {
        state = [];
    }

    if (action.type === formConstants.FORM_LOADING) {
        return {
            type: 'loading'
        }
    }

    if (action.type === formConstants.FORM_ERROR) {
        return {
            type: 'error',
            message: action.message
        }
    }

    if (action.type === formConstants.FORM_SENT) {
        return {
            type: 'success',
            message: action.message
        }
    }

    if (action.type === formConstants.FORM_RESET) {
        return  {}
    }

    return state;
};