import React from "react";
import {routeConstants} from "../../constants/RouteConstants";
import {TaskStatus} from "../../widgets/TaskStatus";

export function WidgetMosTasks(props) {
    let dateFormat = require('dateformat');

    return (
        <div className="card">
                <div className="card-header">
                    Тендеры Mos.ru
                </div>
                <div className="card-body">
                    <table className="table table-sm table-xs table-light table-striped table-condensed">
                        <thead>
                        <tr>
                            <th>Окончание</th>
                            <th>Тендер</th>
                            <th>Статус</th>
                        </tr>
                        </thead>
                        <tbody>
                            {
                                props.items !== null
                                && props.items.length > 0
                                ? props.items.map((item) => (
                                    <tr key={item.ID}>
                                        <td style={{width: "100px"}}>{dateFormat(new Date(item.EndTime), "HH:MM dd.mm.yy")}</td>
                                        <td>
                                            <a href={item.Link} target="_blank" rel="noopener noreferrer">{item.Name}</a>
                                        </td>
                                        <td style={{width: "76px"}}>
                                            <TaskStatus status={item.State}/>
                                        </td>
                                    </tr>
                                )) :
                                <tr>
                                    <td colSpan="2">У вас нет еще ни одной задачи</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    <a href={routeConstants.MOS_TASKS} className="card-link">Все тендеры</a>
                </div>
            </div>
    );
}

