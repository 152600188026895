import React from "react"

function SignInForm(props) {

    return (
        <article className="card-body">
            <h4 className="card-title text-center mb-4 mt-1">Вход</h4>
            <hr />
            {props.result.type === 'error' && <div className="alert alert-danger">{props.result.message}</div>}
            {props.result.type === 'success' && <div className="alert alert-success">{props.result.message}</div>}
            <form name="form" onSubmit={props.onSubmit}>
                <div className="form-group">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text"> <i className="fa fa-user"></i> </span>
                        </div>
                        <input name="username" className="form-control" id="login" placeholder="Login" onChange={props.onChange}/>
                    </div>
                </div>
                <div className="form-group">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text"> <i className="fa fa-lock"></i> </span>
                        </div>
                        <input name="password" className="form-control" id="password" placeholder="******" type="password" onChange={props.onChange}/>
                    </div>
                </div>
                <div className="form-group">
                    <button type="submit" className="btn btn-primary btn-block" disabled={props.result.type === 'loading'}>
                        {props.result.type === 'loading' && <span className="spinner-border spinner-border-sm" role="status"></span>} Вход
                    </button>
                </div>
            </form>
        </article>
    );
}

export default SignInForm;