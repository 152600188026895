import React from "react"
import {WidgetMosTasks} from "../views/WidgetMosTasks";
import {WidgetMosregTasks} from "../views/WidgetMosregTasks";
import {WidgetMosOffers} from "../views/WidgetMosOffers";
import {WidgetMosContracts} from "../views/WidgetMosContracts";
import {WidgetMosregContracts} from "../views/WidgetMosregContracts";
import Axios from "axios";
import {apiConstants} from "../../constants/ApiConstants";
import {Loading} from "../../widgets/Loading";
import {WidgetChart} from "../views/WidgetChart";
import {connect} from "react-redux";

export class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.userRole = this.props.user.role.toUpperCase();

        this.state = {
            isLoading: true,
            mosTasks: [],
            mosregTasks: [],
            mosOffers: [],
            mosContracts: [],
            mosregContracts: []
        }
    }

    loadData = () => {
        const token = localStorage.getItem('token');
        const config = {
            headers: {
                'Authorization': 'bearer ' + token
            }
        };

        Axios.get(apiConstants.API_URL + "/dashboard", config ).then((response) => {
            const result = response.data;
            const data = result.data;
            this.setState(() => ({
                    taskStat: data.taskStat,
                    mosTasks: data.mosTasks,
                    mosregTasks: data.mosregTasks,
                    mosOffers: data.mosOffers,
                    mosContracts: data.mosContracts,
                    mosregContracts: data.mosregContracts,
                    isLoading: false
                })
            );
        }).catch(err => {
            console.log(err);
        });
    };

    componentDidMount() {
        this.loadData();
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Loading />
            );
        } else {

            return (
                <div className="card-columns">
                    {this.userRole === 'ADMIN' && <WidgetChart items={this.state.taskStat}/>}
                    <WidgetMosTasks items={this.state.mosTasks}/>
                    <WidgetMosregTasks items={this.state.mosregTasks}/>
                    <WidgetMosOffers items={this.state.mosOffers}/>
                    <WidgetMosContracts items={this.state.mosContracts}/>
                    <WidgetMosregContracts items={this.state.mosregContracts}/>
                </div>
            )
        }
    }
}

function mapStateToProps(store) {
    return {
        user: store.userReducer
    };
}

export let DashboardContainer = connect(mapStateToProps)(Dashboard);
