import React from "react"
import {ContentLayout} from "../../layouts/ContentLayout";
import {useParams} from "react-router";
import MosregTaskFormContainer from "../../components/containers/MosregTaskFormContainer";

export function MosregTaskForm(props) {

    let {id} = useParams();

    return (
        <ContentLayout title={id ? "Редактирование задачи Mosreg.ru" : "Добавление задачи Mosreg.ru"}>
            <div className="row">
                <div className="col-6 p-0">
                    <br />
                    <MosregTaskFormContainer id={id} />
                </div>
            </div>
        </ContentLayout>
    );
}
