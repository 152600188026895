import React from "react";

export function Pagination(props) {

    const p  = props.pagination;

    return (
        <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center">
                <li className="page-item">
                    <button onClick={() => props.handle(p.Back)} className="page-link" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only">Назад</span>
                    </button>
                </li>
                {p.Pages.map((page, index) => (
                    <li key={index} className="page-item">
                        <button onClick={() => props.handle(page)} className="page-link" >{page}</button>
                    </li>
                ))}
                <li className="page-item">
                    <button onClick={() => props.handle(p.Next)} className="page-link" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                        <span className="sr-only">Далее</span>
                    </button>
                </li>
            </ul>
        </nav>
    );
}