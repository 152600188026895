let kladr = {
  "0100000000000": "Респ Адыгея",
  "0200000000000": "Респ Башкортостан",
  "0300000000000": "Респ Бурятия",
  "0400000000000": "Респ Алтай",
  "0500000000000": "Респ Дагестан",
  "0600000000000": "Респ Ингушетия",
  "0700000000000": "Респ Кабардино-Балкарская",
  "0800000000000": "Респ Калмыкия",
  "0900000000000": "Респ Карачаево-Черкесская",
  "1000000000000": "Респ Карелия",
  "1100000000000": "Респ Коми",
  "1200000000000": "Респ Марий Эл",
  "1300000000000": "Респ Мордовия",
  "1400000000000": "Респ Саха /Якутия/",
  "1500000000000": "Респ Северная Осетия - Алания",
  "1600000000000": "Респ Татарстан",
  "1700000000000": "Респ Тыва",
  "1800000000000": "Респ Удмуртская",
  "1900000000000": "Респ Хакасия",
  "2000000000000": "Респ Чеченская",
  "2100000000000": "Чувашская Республика - Чувашия",
  "2200000000000": "Алтайский край",
  "2300000000000": "Краснодарский край",
  "2400000000000": "Красноярский край",
  "2500000000000": "Приморский край",
  "2600000000000": "Ставропольский край",
  "2700000000000": "Хабаровский край",
  "2800000000000": "Амурская обл",
  "2900000000000": "Архангельская обл",
  "3000000000000": "Астраханская обл",
  "3100000000000": "Белгородская обл",
  "3200000000000": "Брянская обл",
  "3300000000000": "Владимирская обл",
  "3400000000000": "Волгоградская обл",
  "3500000000000": "Вологодская обл",
  "3600000000000": "Воронежская обл",
  "3700000000000": "Ивановская обл",
  "3800000000000": "Иркутская обл",
  "3900000000000": "Калининградская обл",
  "4000000000000": "Калужская обл",
  "4100000000000": "Камчатский край",
  "4200000000000": "Кемеровская область - Кузбасс",
  "4300000000000": "Кировская обл",
  "4400000000000": "Костромская обл",
  "4500000000000": "Курганская обл",
  "4600000000000": "Курская обл",
  "4700000000000": "Ленинградская обл",
  "4800000000000": "Липецкая обл",
  "4900000000000": "Магаданская обл",
  "5000000000000": "Московская обл",
  "5100000000000": "Мурманская обл",
  "5200000000000": "Нижегородская обл",
  "5300000000000": "Новгородская обл",
  "5400000000000": "Новосибирская обл",
  "5500000000000": "Омская обл",
  "5600000000000": "Оренбургская обл",
  "5700000000000": "Орловская обл",
  "5800000000000": "Пензенская обл",
  "5900000000000": "Пермский край",
  "6000000000000": "Псковская обл",
  "6100000000000": "Ростовская обл",
  "6200000000000": "Рязанская обл",
  "6300000000000": "Самарская обл",
  "6400000000000": "Саратовская обл",
  "6500000000000": "Сахалинская обл",
  "6600000000000": "Свердловская обл",
  "6700000000000": "Смоленская обл",
  "6800000000000": "Тамбовская обл",
  "6900000000000": "Тверская обл",
  "7000000000000": "Томская обл",
  "7100000000000": "Тульская обл",
  "7200000000000": "Тюменская обл",
  "7300000000000": "Ульяновская обл",
  "7400000000000": "Челябинская обл",
  "7500000000000": "Забайкальский край",
  "7600000000000": "Ярославская обл",
  "7700000000000": "г Москва",
  "7800000000000": "г Санкт-Петербург",
  "7900000000000": "Еврейская Аобл",
  "8300000000000": "Ненецкий АО",
  "8600000000000": "Ханты-Мансийский Автономный округ - Югра АО",
  "8700000000000": "Чукотский АО",
  "8900000000000": "Ямало-Ненецкий АО",
  "9100000000000": "Респ Крым",
  "9200000000000": "г Севастополь",
  "9900000000000": "г Байконур",
};

export default kladr;