import React from "react"
import {connect} from "react-redux";
import {formActions} from "../../actions/FormActions";
import {keywordActions} from "../../actions/KeywordActions";
import Axios from "axios";
import {Loading} from "../../widgets/Loading";
import {apiConstants} from "../../constants/ApiConstants";
import {acccessConstants} from "../../constants/AccessConstants";

class AddKeywordFormContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            keyword: '',
            user: '',
            users: []
        };

        this.user = props.user;
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(formActions.reset());

        const token = localStorage.getItem('token');
        const config = {
            headers: {
                'Authorization': 'bearer ' + token
            }
        };

        Axios.get(apiConstants.API_URL + "/users", config ).then((response) => {
            const result = response.data;
            const items = result.data.Items;

            if (items && items.length > 0) {
                this.setState(state => ({users: items, user: items[0].Login, isLoading: false}));
            }
        });
    }

    handleChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    handleSubmit(e) {
        e.preventDefault();

        const { dispatch } = this.props;
        dispatch(formActions.loading());

        if (this.state.keyword.length < 1) {
            dispatch(formActions.error("Укажите слово"));
            return
        }

        dispatch(keywordActions.add({keyword: this.state.keyword, user: this.state.user}));
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Loading />
            );
        } else {

            let userChoose = '';

            if (this.user.role.toUpperCase() === acccessConstants.ADMIN) {
                userChoose =
                    <div className="form-group col-12">
                        <label htmlFor="keyword">Пользователь</label>
                        <select value={this.state.user} name="user" className="form-control" onChange={this.handleChange}>
                            {
                                this.state.users.length > 0 &&
                                this.state.users.map((elem) => (
                                   <option key={elem.Login} value={elem.Login}>{elem.Login}</option>
                                ))
                            }
                        </select>
                    </div>;
            }

            return (
                <form name="form" onSubmit={this.handleSubmit}>
                    {this.props.result.type === 'error' && <div className="alert alert-danger">{this.props.result.message}</div>}
                    {this.props.result.type === 'success' && <div className="alert alert-success">{this.props.result.message}</div>}
                    <div className="form-group col-12">
                        <label htmlFor="keyword">Ключевое слово</label>
                        <input name="keyword" type="text" className="form-control" required id="keyword" placeholder="Слово для поиска" onChange={this.handleChange} value={this.state.login}/>
                        <small id="keywordHelp" className="form-text text-muted">Введите ключево слово для отбора тендеров</small>
                    </div>
                    {userChoose}
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary" disabled={this.props.result.type === 'loading'}>
                            {this.props.result.type === 'loading' && <span className="spinner-border spinner-border-sm" role="status"></span>} Сохранить
                        </button>
                    </div>
                </form>
            );

            //return <AddKeywordForm data={this.state} user={this.user} result={this.props.result} onChange={this.handleChange} onSubmit={this.handleSubmit}/>;
        }
    }
}

function mapStateToProps(store) {
    return {
        result: store.formReducer,
        user: store.userReducer
    };
}

export default connect(mapStateToProps)(AddKeywordFormContainer);