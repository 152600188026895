import {formConstants} from "../constants/FormConstants";
import {history} from "../app/History";
import {routeConstants} from "../constants/RouteConstants";
import {stopwordService} from "../services/StopwordServices";

export const stopwordActions = {
    add,
    update
};

function add(params) {
    return dispatch => {
        stopwordService.add(params)
            .then(response => {
                if (response.status === 200) {
                    const result = response.data;
                    if (result.status === "error") {
                        dispatch({type: formConstants.FORM_ERROR, message: result.message})
                    } else {
                        dispatch({type: formConstants.FORM_SENT, message: "Стоп слово добавлено"});
                        history.push(routeConstants.PARSER_STOPWORDS);
                    }
                } else {
                    dispatch({type: formConstants.FORM_ERROR, message: "Ошибка сервера"})
                }
            })
            .catch(() => {
                dispatch({type: formConstants.FORM_ERROR, message: "Ошибка сервера"})
            });
    }
}

function update(id, params) {
    return dispatch => {
        stopwordService.update(id, params)
            .then(response => {
                if (response.status === 200) {
                    const result = response.data;
                    if (result.status === "error") {
                        dispatch({type: formConstants.FORM_ERROR, message: result.message})
                    } else {
                        dispatch({type: formConstants.FORM_SENT, message: "Изменения сохранены"});
                        history.push(routeConstants.PARSER_KEYWORDS);
                    }
                } else {
                    dispatch({type: formConstants.FORM_ERROR, message: "Ошибка сервера"})
                }
            })
            .catch(() => {
                dispatch({type: formConstants.FORM_ERROR, message: "Ошибка сервера"})
            });
    }
}