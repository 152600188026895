import React from "react"
import {connect} from "react-redux";
import {formActions} from "../../actions/FormActions";
import {stopwordActions} from "../../actions/StopwordActions";
import {AddStopwordForm} from "../views/AddStopwordForm";

class StopwordAddFormContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            stopword: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    handleSubmit(e) {
        e.preventDefault();

        const { dispatch } = this.props;
        dispatch(formActions.loading());

        if (this.state.stopword.length < 1) {
            dispatch(formActions.error("Укажите слово"));
            return
        }

        dispatch(stopwordActions.add({stopword: this.state.stopword}));
    }

    render() {
        return <AddStopwordForm data={this.state} result={this.props.result} onChange={this.handleChange} onSubmit={this.handleSubmit}/>
    }
}

function mapStateToProps(store) {
    return {
        result: store.formReducer
    };
}

export default connect(mapStateToProps)(StopwordAddFormContainer);