import React from "react"
import {ContentLayout} from "../layouts/ContentLayout";
import {DashboardContainer} from "../components/containers/DashboardContainer";

export function HomePage(props) {
    return (
        <ContentLayout>
            <DashboardContainer />
        </ContentLayout>
    );
}