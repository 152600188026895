import React from "react"
import {Loading} from "../../widgets/Loading";
import {connect} from "react-redux";
import {formActions} from "../../actions/FormActions";
import {userService} from "../../services/UserServices";
import {userActions} from "../../actions/UserActions";
import {UserFormView} from "../views/UserFormView";
import {apiConstants} from "../../constants/ApiConstants";
import Axios from "axios";
import {withRouter} from "react-router";

class UserFormContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            login: '',
            email: '',
            password: '',
            repassword: '',
            role: 'User',
            selectedMosAccounts: [],
            mosAccounts: [],
            selectedMosregAccounts: [],
            mosregAccounts: [],
            selectedEatAccounts: [],
            eatAccounts: [],
        };

        this.id = props.id;
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(formActions.reset());

        const loadAccounts = async () => {
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    'Authorization': 'bearer ' + token
                }
            };

            const mos = await Axios.get(apiConstants.API_URL + "/mos/accounts", config)
            const mo = await Axios.get(apiConstants.API_URL + "/mo/accounts", config)
            const eat = await Axios.get(apiConstants.API_URL + "/eat/accounts", config)

            return {mos, mo, eat}
        }

        loadAccounts().then(({mos, mo, eat}) => {
            const mosAccounts = mos.data.data;
            const moAccounts = mo.data.data;
            const eatAccounts = eat.data.data;

            if (this.id) {
                userService.get(this.id, (result) => {
                    let selectedMosAccounts = [];
                    let selectedMosregAccounts = [];
                    let selectedEatAccounts = [];

                    if (result.Settings) {
                        result.Settings.forEach(id => {
                            selectedMosAccounts.push({value: id, label: mosAccounts[id]})
                        })
                    }

                    if (result.SettingsMO) {
                        result.SettingsMO.forEach(id => {
                            selectedMosregAccounts.push({value: id, label: moAccounts[id]})
                        })
                    }

                    if (result.SettingsEat) {
                        result.SettingsEat.forEach(id => {
                            selectedEatAccounts.push({value: id, label: eatAccounts[id]})
                        })
                    }

                    this.setState({
                        isLoading: false,
                        login: result.Login,
                        email: result.Email,
                        role: result.Role,
                        selectedMosAccounts: selectedMosAccounts,
                        selectedMosregAccounts: selectedMosregAccounts,
                        selectedEatAccounts: selectedEatAccounts,
                        mosAccounts: mosAccounts,
                        mosregAccounts: moAccounts,
                        eatAccounts: eatAccounts
                    })
                }).catch(() => {
                    this.props.history.push({
                        pathname: `/error`,
                    });
                });
            } else {
                this.setState({
                    isLoading: false,
                    mosAccounts: mosAccounts,
                    mosregAccounts: moAccounts,
                    eatAccounts: eatAccounts
                })
            }
        })
    }

    handleChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    mosAccountHandler = (value) => {
        console.log(value);
        this.setState({selectedMosAccounts: value})
    }

    mosregAccountHandler = (value) => {
        this.setState({selectedMosregAccounts: value})
    }

    eatAccountHandler = (value) => {
        this.setState({selectedEatAccounts: value})
    }

    handleSubmit(e) {
        e.preventDefault();

        const { dispatch } = this.props;
        dispatch(formActions.loading());

        if (this.state.login.length < 1) {
            dispatch(formActions.error("Логин не может быть пустым"));
            return
        }

        if (!this.id && this.state.password.length < 1) {
            dispatch(formActions.error("Укажите пароль"));
            return
        }

        if (this.state.login.length < 1) {
            dispatch(formActions.error("Укажите логин"));
            return
        }

        if (this.state.password !== this.state.repassword) {
            dispatch(formActions.error("Пароли не совпадают"));
            return
        }

        const params = new URLSearchParams();
        params.append('login', this.state.login);
        params.append('email', this.state.email);
        if (this.state.password.length > 0) {
            params.append('password', this.state.password);
        }
        params.append('role', this.state.role);

        this.state.selectedMosAccounts.forEach(account => {
            params.append('mos_accounts', account.value);
        });

        this.state.selectedMosregAccounts.forEach(account => {
            params.append('mosreg_accounts', account.value);
        });

        this.state.selectedEatAccounts.forEach(account => {
            params.append('eat_accounts', account.value);
        });

        if (this.id) {
            dispatch(userActions.update(this.id, params));
        } else {
            dispatch(userActions.add(params));
        }
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Loading />
            );
        } else {
            return <UserFormView
              data={this.state}
              result={this.props.result}
              onChange={this.handleChange}
              onSubmit={this.handleSubmit}
              onMosHandler={this.mosAccountHandler}
              onMosregHandler={this.mosregAccountHandler}
              onEatHandler={this.eatAccountHandler}
            />
        }
    }
}

function mapStateToProps(store) {
    return {
        result: store.formReducer
    };
}

export default connect(mapStateToProps)(withRouter(UserFormContainer));