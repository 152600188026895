import Axios from "axios";
import {apiConstants} from "../constants/ApiConstants";

export let settingsService = {
    prefix: '',
    token: localStorage.getItem('token'),
    setPrefix,
    get,
    update,
    add
};

function setPrefix(prefix) {
    this.prefix = prefix;
    return this;
}

function get(id, handler) {
    const config = {
        headers: {
            'Authorization': 'bearer ' + this.token
        }
    };
    let url = '';
    if (id.length > 0) {
        url = apiConstants.API_URL + '/' + this.prefix + 'settings/' + id;
    } else {
        url = apiConstants.API_URL + '/' + this.prefix + 'settings';
    }

    Axios.get(url, config)
        .catch()
        .then(response => {
            if (response.status === 200) {
                const result = response.data;
                if (result.status === 'success') {
                    const data = result.data;
                    handler(data)
                }
            }
        })
}

function update(id, params) {
    const config = {
        headers: {
            'Authorization': 'bearer ' + this.token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };

    let url = '';
    if (id.length > 0) {
        url = apiConstants.API_URL + '/' + this.prefix + 'settings/' + id;
    } else {
        url = apiConstants.API_URL + '/' + this.prefix + 'settings';
    }

    return Axios.put(url, params, config)
        .then((response) => {
            return response;
        })
}

function add(params) {
    const config = {
        headers: {
            'Authorization': 'bearer ' + this.token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };

    return Axios.post(apiConstants.API_URL + '/' + this.prefix + 'settings', params, config)
      .then((response) => {
          return response;
      })
}
