import React from "react"
import {TaskStatus} from "../../widgets/TaskStatus";
import {headerSortingClasses, sortCaret} from "../../widgets/TableSortingHelpers";
import dateFormat from "dateformat";
import FilteredTable from "../../components/containers/FilteredTable";
import {apiConstants} from "../../constants/ApiConstants";

export function MosWaitList() {

  let filterView = () => {
    return '';
  }

  const columns = [
    {
      dataField: "SettingsID",
      text: "Аккаунт",
      headerClasses: "text-nowrap",
      classes: "text-nowrap",
    },
    {
      dataField: "EndTime",
      text: "Окончание",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: function (cell, row) {
        return dateFormat(row.EndTime, "HH:MM dd.mm.yy");
      },
    },
    {
      dataField: "Name",
      text: "Название",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: function (cell, row) {
        return (<a href={row.Link} target="_blank">{row.Name}</a>);
      },
    },
    {
      dataField: "StartPrice",
      text: "Старт.цена",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "SecondPrice",
      text: "Стоп.цена",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "FirstPrice",
      text: "Закупка",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "LastPrice",
      text: "Посл.цена",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "User",
      text: "Создал",
      sort: false
    },
    {
      dataField: "State",
      text: "Статус",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: function (cell, row) {
        return (<TaskStatus status={row.State}/>);
      },
    }
  ];

  return (
    <>
      <FilteredTable
        sortField={"EndTime"}
        dataUrl={apiConstants.API_URL + '/contract/wait'}
        filterView={filterView}
        columns={columns}
        title={"Ожидание контрактов на Mos.ru"}
      />
    </>
  );
}