import React from "react";
import {routeConstants} from "../../constants/RouteConstants";

export function WidgetMosregContracts(props) {
    let dateFormat = require('dateformat');

    return (
        <div className="card">
            <div className="card-header">
                Контракты Mosreg.ru
            </div>
            <div className="card-body">
                <table className="table table-sm table-xs table-light table-striped table-condensed">
                    <thead>
                    <tr>
                        <th className="text-nowrap">Подписать до</th>
                        <th>Контракт</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        props.items !== null && props.items.length > 0 ?
                        props.items.map((item) => (
                            <tr key={item.ID}>
                                <td className="text-nowrap" style={{width: "100px"}}>{dateFormat(new Date(item.CreateDate), "HH:MM dd.mm.yy")}</td>
                                <td>
                                    <a href={item.Url} target="_blank" rel="noopener noreferrer">{item.Name}</a>
                                    <br />
                                    {item.Status === "Заключен" ? <span className="state-4 badge badge-success">{item.Status}</span> : <span className="state-4 badge badge-warning">{item.Status}</span>}
                                </td>
                            </tr>
                        )) :
                            <tr>
                                <td colSpan="2">У вас нет еще ни одного контракта</td>
                            </tr>
                    }
                    </tbody>
                </table>
                <a href={routeConstants.MOS_CONTRACTS} className="card-link">Все контракты</a>
            </div>
        </div>
    );
}