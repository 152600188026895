import React from "react"
import {ContentLayout} from "../../layouts/ContentLayout";
import {useParams} from "react-router";
import MosTaskFormContainer from "../../components/containers/MosTaskFormContainer";

export function MosTaskForm() {

    let {id} = useParams();

    return (
        <ContentLayout title={id ? "Редактирование задачи Mos.ru" : "Добавление задачи Mos.ru"}>
            <div className="row">
                <div className="col-6 p-0">
                    <br />
                    <MosTaskFormContainer id={id} />
                </div>
            </div>
        </ContentLayout>
    );
}
