import React from 'react'
import {Link} from 'react-router-dom'
import {routeConstants} from "../constants/RouteConstants";
import {connect} from "react-redux";

const menu = [
    {
        title: "Главная",
        url: routeConstants.MAIN_PAGE,
        access: 'All'
    },
    {
        title: "Mos.ru",
        url: "#",
        items: [
            {
                title: "Задачи роботу",
                url: routeConstants.MOS_TASKS,
                access: 'All'
            },
            {
                title: "Оферты и Контракты",
                url: routeConstants.MOS_DOCUMENTS,
                access: 'All'
            },
            {
                title: "Ожидание контрактов",
                url: routeConstants.MOS_WAIT_LIST,
                access: 'All'
            },
            {
                title: "Исполнение контрактов",
                url: routeConstants.MOS_EXECUTIONS,
                access: 'All'
            },
            {
                title: "Конкуренты",
                url: routeConstants.MOS_COMPETITORS,
                access: 'All'
            },
            {
                title: "Управление аккаунтами",
                url: routeConstants.MOS_SETTINGS,
                access: 'Admin'
            }
        ],
        access: 'All'
    },
    {
        title: "Mosreg.ru",
        url: "#",
        items: [
            {
                title: "Задачи роботу",
                url: routeConstants.MOSREG_TASKS,
                access: 'All'
            },
            {
                title: "Контракты",
                url: routeConstants.MOSREG_CONTRACTS,
                access: 'All'
            },
            {
                title: "Ожидание контрактов",
                url: routeConstants.MOSREG_WAIT_LIST,
                access: 'All'
            },
            {
                title: "Конкуренты",
                url: routeConstants.MOSREG_COMPETITORS,
                access: 'All'
            },
            {
                title: "Управление аккаунтами",
                url: routeConstants.MOSREG_SETTINGS,
                access: 'Admin'
            }
        ],
        access: 'All'
    },
    {
        title: "Берёзка",
        url: "#",
        items: [
            {
                title: "Задачи роботу",
                url: routeConstants.EAT_TASKS,
                access: 'All'
            },
            {
                title: "Контракты",
                url: routeConstants.EAT_CONTRACTS,
                access: 'All'
            },
            {
                title: "Управление аккаунтами",
                url: routeConstants.EAT_SETTINGS,
                access: 'Admin'
            }
        ],
        access: 'All'
    },
    {
        title: "Парсер",
        url: "#",
        items: [
            /*{
                title: "Результаты",
                url: routeConstants.PARSER_RESULT,
                access: "All"
            },*/
            {
                title: "Ключевые слова",
                url: routeConstants.PARSER_KEYWORDS,
                access: "All"
            },
            {
                title: "Стоп слова",
                url: routeConstants.PARSER_STOPWORDS,
                access: "All"
            }
        ],
        access: "All"
    },
    {
        title: "Поиск тендеров",
        url: routeConstants.SEARCH,
        access: "All"
    },
    {
        title: "Профиль",
        url: routeConstants.USER_PROFILE,
        access: "All"
    },
    {
        title: "Админ",
        url: "#",
        items: [
            {
                title: "Список пользователей",
                url: routeConstants.USERS,
                access: 'Admin'
            },
            {
                title: "Настройки",
                url: routeConstants.APP_SETTINGS,
                access: 'Admin'
            },
            {
                title: "Интеграция с Битрикс24",
                url: routeConstants.B24_INTEGRATION,
                access: 'Admin'
            }
        ],
        access: 'Admin'
    }
];

export class Menu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {isToggleOn: false};
        this.handleClick = this.handleClick.bind(this)
        this.handleLogout = this.handleLogout.bind(this)
    }

    handleClick() {
        this.setState(state => ({
            isToggleOn: !state.isToggleOn
        }));
    }

    handleLogout(e) {
        e.preventDefault();

        localStorage.removeItem('token');
        window.location.reload();
    }

    render() {
        return (
            <nav className="row navbar navbar-expand-lg navbar-light bg-light">
                <a onClick={this.handleClick} className="navbar-brand" href="/">
                    <img src="/images/logo.png" height="30" className="d-inline-block align-top" alt="" />
                </a>
                <button onClick={this.handleClick} className="navbar-toggler" type="button">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <NavBarBody showed={this.state.isToggleOn}>
                    <button onClick={this.handleLogout} className="btn btn-sm btn-outline-secondary">Выход</button>
                </NavBarBody>
            </nav>
        );
    }
}

function NavBarBody(props) {
    return (
        <div className={props.showed ? "collapse navbar-collapse show" : "collapse navbar-collapse"} id="navbarSupportedContent">
            <MenuListContainer/>
            {props.children}
        </div>
    )
}

function mapStateToProps(store) {
    return {
        user: store.userReducer
    };
}

class MenuList extends React.Component {
    constructor(props) {
        super(props);

        this.userRole = this.props.user.role.toUpperCase();
        this.state = {opened: null};
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClick(index, e) {
        this.setState(state => ({
            opened: index
        }));
    }

    handleClickOutside(e) {
        if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
            this.setState(state => ({
                opened: null
            }));
        }
    }

    render() {
        const menuItems  = menu.map((item, index) => {
            if (item.access.toUpperCase() === this.userRole || item.access === 'All') {
                if (item.items !== undefined && item.items.length > 0) {
                    const subItems = item.items.map((child, index) => {
                        if (child.access.toUpperCase() === this.userRole || child.access === 'All') {
                            return (
                                <Link key={index} onClick={this.handleClick.bind(this)} className="dropdown-item"
                                      to={child.url}>{child.title}</Link>
                            );
                        }

                        return '';
                    });

                    return (
                        <li key={index} className="nav-item dropdown">
                            <Link onClick={this.handleClick.bind(this, index)} className="nav-link dropdown-toggle"
                                  to={item.url}>
                                {item.title}
                            </Link>
                            <div className={this.state.opened === index ? "dropdown-menu show" : "dropdown-menu"}>
                                {subItems}
                            </div>
                        </li>
                    );
                } else {
                    return (
                        <MenuListItem key={index} url={item.url} title={item.title}/>
                    );
                }
            }

            return '';
        });

        return (
            <ul className="navbar-nav mr-auto" ref={this.setWrapperRef}>
                {menuItems}
            </ul>
        )
    }
}

let MenuListContainer = connect(mapStateToProps)(MenuList);

function MenuListItem(props) {
    return (
        <li className="nav-item">
            <Link className="nav-link" to={props.url} tabIndex="-1" aria-disabled="true">{props.title}</Link>
        </li>
    );
}
