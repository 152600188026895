import React from "react"
import {Loading} from "../../widgets/Loading";
import {connect} from "react-redux";
import {formActions} from "../../actions/FormActions";
import {settingsService} from "../../services/SettingsService";
import {AppSettingsForm} from "../views/AppSettingsForm";
import {settingsActions} from "../../actions/SettingsActions";

class AppSettingsFormContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            sendEmail: false,
            email: '',
            sendTelegram: false,
            telegramId: '',
            sendWhatsapp: false,
            toWhatsapp: '',
            domain: ''
        };

        this.prefix = 'app';
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(formActions.reset());

        settingsService.setPrefix(this.prefix).get("", (data) => {
            this.setState({
                sendEmail: data.SendEmail,
                email: data.Email,
                sendTelegram: data.send_telegram,
                telegramId: data.id,
                sendWhatsapp: data.send_whatsapp,
                toWhatsapp: data.to_whatsapp,
                domain: data.Domain.length > 0 ? data.Domain : window.location.hostname
            })
        });
    }

    handleChange(e) {
        const name = e.target.name;
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({[name]: value});
    }

    handleSubmit(e) {
        e.preventDefault();

        const { dispatch } = this.props;
        dispatch(formActions.loading());

        const params = new URLSearchParams();
        params.append('send_email', this.state.sendEmail ? 'yes' : 'no');
        params.append('send_telegram', this.state.sendTelegram ? 'yes' : 'no');
        params.append('email', this.state.email);
        params.append('domain', this.state.domain);
        params.append('send_whatsapp', this.state.sendWhatsapp ? 'yes' : 'no');
        params.append('to_whatsapp', this.state.toWhatsapp);

        dispatch(settingsActions.update("", params, this.prefix));
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Loading />
            );
        } else {
            return <AppSettingsForm data={this.state} result={this.props.result} onChange={this.handleChange} onSubmit={this.handleSubmit}/>
        }
    }
}

function mapStateToProps(store) {
    return {
        user: store.userReducer,
        result: store.formReducer
    };
}

export default connect(mapStateToProps)(AppSettingsFormContainer);