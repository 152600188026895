import React from "react"
import InputMask from 'react-input-mask';

export function MosregSettingsForm(props) {
  return (
    <form name="form" onSubmit={props.onSubmit}>
      {props.result.type === 'error' && <div className="alert alert-danger">{props.result.message}</div>}
      <div className="form-group">
        <label htmlFor="name">Название организации</label>
        <input name="name" type="text" className="form-control" id="name" value={props.data.name} placeholder="Название"
               onChange={props.onChange}/>
        <small id="loginHelp" className="form-text text-muted">
          Краткое название организации для которой этот аккаунт
        </small>
      </div>
      <div className="form-group">
        <label htmlFor="login">Логин</label>
        <input name="login" type="text" className="form-control" id="login" value={props.data.login} placeholder="Логин"
               onChange={props.onChange}/>
        <small id="loginHelp" className="form-text text-muted">
          Введите ваш логин на портале mosreg.ru
        </small>
      </div>
      <div className="form-group">
        <label htmlFor="password">Пароль</label>
        <input name="password" type="text" className="form-control" id="password" value={props.data.password}
               placeholder="Пароль" onChange={props.onChange}/>
        <small id="passwordHelp" className="form-text text-muted">
          Введите ваш пароль на портале mosreg.ru
        </small>
      </div>
      <div className="row">
        <div className="form-group col-4">
          <label htmlFor="time">НДС</label>
          <select value={props.data.tax} name="tax" className="form-control" onChange={props.onChange}>
            <option value="no">Без НДС</option>
            <option value="0">НДС 0%</option>
            <option value="10">НДС 10%</option>
            <option value="18">НДС 18%</option>
            <option value="20">НДС 20%</option>
          </select>
          <small id="timeHelp" className="form-text text-muted">
            Выберите как ваша организация работает с НДС, настройка используется при создании предложения в закупке
          </small>
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="time">Сертификат</label>
        <select value={props.data.cert} name="cert" className="form-control" onChange={props.onChange}>
          <option key="empty" value="">- Выберите сертификат -</option>
          {props.data.certs !== null ? Object.keys(props.data.certs).map((key) => {
            let cert = props.data.certs[key];
            return <option key={cert.Id} value={cert.Id}>{cert.Name}</option>
          }) : <option key="empty" value="">- Нет установленных сертификатов -</option>}
        </select>
        <small id="timeHelp" className="form-text text-muted">
          Выберите сертификат для подписания предложения на закупку
        </small>
      </div>
      <button type="submit" className="btn btn-primary" disabled={props.result.type === 'loading'}>
        {props.result.type === 'loading' && <span className="spinner-border spinner-border-sm"></span>} Сохранить
      </button>
    </form>
  );
}