import React, {Fragment} from "react"
import {ContentLayout} from "../../layouts/ContentLayout";
import {UserListContainer} from "../../components/containers/UserListContainer";
import {Link} from "react-router-dom";
import {routeConstants} from "../../constants/RouteConstants";
import {connect} from "react-redux";
import {acccessConstants} from "../../constants/AccessConstants";
import {Forbidden} from "../Forbidden";

function mapStateToProps(store) {
    return {
        user: store.userReducer
    };
}

export let Users = connect(mapStateToProps)(function(props) {
    return (
        <ContentLayout title="Пользователи">
            {props.user.role.toUpperCase() === acccessConstants.ADMIN ?
                <Fragment>
                    <Link to={routeConstants.USER_ADD} className="btn btn-primary btn-sm">Добавить пользователя</Link>
                    <br /><br />
                    <UserListContainer />
                </Fragment> : <Forbidden/>
            }
        </ContentLayout>
    );
});