import {userConstants} from "../constants/UserConstants";

export let userReducer = function(state, action) {
    if (state === undefined) {
        state = [];
    }

    if (action.type === userConstants.ADD_USER) {
        return action.user;
    }

    if (action.type === userConstants.DELETE_USER) {
        return {}
    }

    return state;
};