import React from "react"
import {Loading} from "../../widgets/Loading";
import {connect} from "react-redux";
import {formActions} from "../../actions/FormActions";
import {settingsService} from "../../services/SettingsService";
import {settingsActions} from "../../actions/SettingsActions";
import Axios from "axios";
import {apiConstants} from "../../constants/ApiConstants";

class EatSettingsFormContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: !!props.id,
            name: '',
            login: '',
            password: '',
            startBefore: 0,
            cert: {},
            certs: [],
            tax: 'no'
        };
        this.id = props.id;

        this.prefix = 'eat';
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if (this.id) {
            const {dispatch} = this.props;
            dispatch(formActions.reset());

            settingsService.setPrefix(this.prefix).get(this.id,(data) => {
                this.setState({
                    isLoading: false,
                    name: data.Name,
                    login: data.Login,
                    password: data.Password,
                    startBefore: data.Time,
                    cert: data.UserCert,
                    certs: data.Certs,
                    tax: data.Tax
                });
            });
        } else {
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    'Authorization': 'bearer ' + token
                }
            };

            Axios.get(apiConstants.API_URL + '/cert', config).then((resp) => {
                const {data} = resp.data;
                this.setState({certs: data})
            });
        }

    }

    handleChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    handleSubmit(e) {
        e.preventDefault();

        const { dispatch } = this.props;
        dispatch(formActions.loading());

        const params = new URLSearchParams();
        params.append('name', this.state.name);
        params.append('login', this.state.login);
        params.append('password', this.state.password);
        params.append('startBefore', this.state.startBefore);
        params.append('cert', this.state.cert);
        params.append('tax', this.state.tax);

        if (this.id) {
            dispatch(settingsActions.update(this.id, params, this.prefix));
        } else {
            dispatch(settingsActions.add(params, this.prefix));
        }
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Loading />
            );
        } else {
            return (
                <form name="form" onSubmit={this.handleSubmit}>
                    {this.props.result.type === 'error' && <div className="alert alert-danger">{this.props.result.message}</div>}
                    <div className="form-group">
                        <label htmlFor="name">Название организации</label>
                        <input name="name" type="text" className="form-control" id="name" value={this.state.name} placeholder="Название"
                               onChange={this.handleChange}/>
                        <small id="loginHelp" className="form-text text-muted">
                            Краткое название организации для которой этот аккаунт
                        </small>
                    </div>
                    <div className="form-group">
                        <label htmlFor="login">Логин</label>
                        <input name="login" type="text" className="form-control" id="login" value={this.state.login} placeholder="Логин" onChange={this.handleChange} />
                        <small id="loginHelp" className="form-text text-muted">
                            Введите ваш логин на Agregatoreat.ru
                        </small>
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Пароль</label>
                        <input name="password" type="text" className="form-control" id="password" value={this.state.password} placeholder="Пароль" onChange={this.handleChange} />
                        <small id="passwordHelp" className="form-text text-muted">
                            Введите ваш пароль на портале Agregatoreat.ru
                        </small>
                    </div>
                    <div className="row">
                        <div className="form-group col-4">
                            <label htmlFor="time">Время</label>
                            <input name="startBefore" type="text" className="form-control" id="time" value={this.state.startBefore} placeholder="Время (минуты)" onChange={this.handleChange} />
                            <small id="timeHelp" className="form-text text-muted">
                                За сколько минут до окончания приема заявок отправлять предложение
                            </small>
                        </div>
                        <div className="form-group col-4">
                            <label htmlFor="time">НДС</label>
                            <select value={this.state.tax} name="tax" className="form-control" onChange={this.handleChange}>
                                <option value="no">Без НДС</option>
                                <option value="0">НДС 0%</option>
                                <option value="10">НДС 10%</option>
                                <option value="18">НДС 18%</option>
                                <option value="20">НДС 20%</option>
                            </select>
                            <small id="timeHelp" className="form-text text-muted">
                                Выберите как ваша организация работает с НДС, настройка используется при создании предложения в закупке
                            </small>
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="time">Сертификат</label>
                        <select value={this.state.cert} name="cert" className="form-control" onChange={this.handleChange}>
                            <option key="empty" value="">- Выберите сертификат -</option>
                            {this.state.certs !== null ? Object.keys(this.state.certs).map((key) => {
                                let cert = this.state.certs[key];
                                return <option key={cert.Id} value={cert.Id}>{cert.Name}</option>
                            }) : <option key="empty" value="">- Нет установленных сертификатов -</option>}
                        </select>
                        <small id="timeHelp" className="form-text text-muted">
                            Выберите сертификат для подписания предложения на закупку
                        </small>
                    </div>
                    <button type="submit" className="btn btn-primary" disabled={this.props.result.type === 'loading'}>
                        {this.props.result.type === 'loading' && <span className="spinner-border spinner-border-sm"></span>} Сохранить
                    </button>
                </form>
            )
        }
    }
}

function mapStateToProps(store) {
    return {
        result: store.formReducer
    };
}

export default connect(mapStateToProps)(EatSettingsFormContainer);