import React from "react";
import Axios from "axios";
import {apiConstants} from "../../constants/ApiConstants";

export function ParseResultList(props) {
    return (
        <div className="table-responsive-lg">
            <table className="table table-light table-striped table-row-border table-condensed">
                <thead>
                <tr>
                    <th className="text-nowrap">ID</th>
                    <th className="text-nowrap">Начало</th>
                    <th className="text-nowrap">Окончание</th>
                    <th className="text-nowrap">Название</th>
                    <th className="text-nowrap">Кл. слова</th>
                    <th className="text-nowrap">Организация</th>
                    <th className="text-nowrap">Цена</th>
                    <th className="text-nowrap">Площадка</th>
                    <th className="text-nowrap">#</th>
                    <th className="text-nowrap">#</th>
                </tr>
                </thead>
                <tbody>
                {
                    props.items !== null && props.items.length > 0 ?
                        props.items.map((item) => (
                        <ParseResultListItem key={item.ID} item={item}/>
                        )) :
                        <tr>
                            <td colSpan="9">Нет результатов парсинга аукционов</td>
                        </tr>
                }
                </tbody>
            </table>
        </div>
    );
}

class ParseResultListItem extends  React.Component {

    constructor(props) {
        super(props);

        this.id = props.item.ID;

        this.handleDelete = this.handleDelete.bind(this);
    }

    handleDelete() {
        const config = {
            headers: {
                'Authorization': 'bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };

        Axios.delete(apiConstants.API_URL + '/auction/' + this.id, config)
            .then((response) => {
                return response;
            })
    }

    render() {
        const item = this.props.item;

        const dateStart = new Date(item.StartTime);
        const dateEnd = new Date(item.EndTime);

        let dateFormat = require('dateformat');

        let link = '';
        let site = '';
        if (item.Site === 1) {
            link = 'https://zakupki.mos.ru/auction/'  + item.RegNum;
            site = 'mos.ru'
        } else if (item.Site === 2) {
            link = 'https://market.mosreg.ru/Trade/ViewTrade/' + item.RegNum;
            site = 'mosreg.ru'
        } else if (item.Site === 3) {
            link = 'https://agregatoreat.ru/purchases/announcement/' + item.Uuid + '/info';
            site = 'agregatoreat.ru'
        }

        const pattern = new RegExp('[\\"|«](.*)[\\"|»]');
        let name = '';
        let result = pattern.exec(item.Organization);
        if (result !== null) {
            name = result[1];
        }

        if (name === null || name.length < 1) {
            name = item.Organization;
        }

        return (
            <React.Fragment key={item.ID}>
                <tr>
                    <td>{item.RegNum}</td>
                    <td className="text-nowrap">{dateFormat(dateStart, "HH:MM dd.mm.yy")}</td>
                    <td className="text-nowrap">{dateFormat(dateEnd, "HH:MM dd.mm.yy")}</td>
                    <td width="50%"><a href={link} target="_blank" rel="noopener noreferrer">{item.Name}</a></td>
                    <td>{item.Keywords !== undefined && item.Keywords !== null && item.Keywords.join(', ')}</td>
                    <td width="25%">{name}</td>
                    <td>{item.StartPrice}</td>
                    <td>
                        {site}
                    </td>
                    <td>
                        {item.Task && <span className="state-4 badge badge-success">Участвуем</span>}
                    </td>
                    <td>
                        <button onClick={this.handleDelete} type="button" className="btn btn-danger delete" title="Удалить">
                            <i className="fa fa-trash"></i>
                        </button>
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}