import React from "react"
import {ContentLayout} from "../../layouts/ContentLayout";
import {useSelector} from "react-redux";
import {acccessConstants} from "../../constants/AccessConstants";
import {Forbidden} from "../Forbidden";
import EatSettingsFormContainer from "../../components/containers/EatSettingsFormContainer";
import {useParams} from "react-router";

export let EatSettingsForm = () => {
  const user = useSelector(state => state.userReducer)

  let {id} = useParams();

  return (
      <ContentLayout title={id ? "Редактирование аккаунта на Берёзке" : "Добавить аккаунт на Березке"}>
          <div className="row">
              <br />
              {
                user.role.toUpperCase() === acccessConstants.ADMIN ?
                  <div className="col-6">
                    <br />
                    <EatSettingsFormContainer id={id} />
                  </div> :
                    <div className="col-12">
                      <Forbidden />
                    </div>
              }
          </div>
      </ContentLayout>
  );
};
