import React from 'react';
import timediff from "timediff";
import {declOfNum} from "./SearchWidget";

export default function TimeToEnd({date}) {
  const diff = timediff(new Date(), new Date(date));

  let toEnd;
  if (diff.days > 0) {
    toEnd = <><i className="fa fa-clock-o" aria-hidden="true"/> {"Осталось " + diff.days + " " + declOfNum(diff.days, ["день", "дня", "дней"]) + " " + diff.hours + " " + declOfNum(diff.hours, ["час", "часа", "часов"])}</>;
  } else if (diff.hours > 0) {
    toEnd = <><i className="fa fa-clock-o" aria-hidden="true"/> {"Осталось " + diff.hours + " " + declOfNum(diff.hours, ["час", "часа", "часов"]) + " " + diff.minutes + " " + declOfNum(diff.minutes, ["минута", "минуты", "минут"])}</>;
  } else if (diff.minutes > 0) {
    toEnd = <><i className="fa fa-clock-o" aria-hidden="true"/> {"Осталось " + diff.minutes + " " + declOfNum(diff.minutes, ["минута", "минуты", "минут"])}</>;
  } else {
    toEnd = ""
  }

  return toEnd
}