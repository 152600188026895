import React from "react";
import Chart from "react-apexcharts";

export class WidgetChart extends React.Component {
    constructor(props) {
        super(props);

        let data = [];
        let xaxis = [];
        let mos = [];
        let mosreg = [];
        let eat = [];

        if (props.items.mos !== undefined && props.items.mos.length > 0) {
            props.items.mos.map((item) => {
                data[item._id.month + "." + item._id.year] = {mos: Math.floor(item.sum), mosreg: 0, eat: 0};
            });
        }

        if (props.items.mosreg !== undefined && props.items.mosreg.length > 0) {
            props.items.mosreg.map((item) => {
                if (data[item._id.month + "." + item._id.year] !== undefined) {
                    data[item._id.month + "." + item._id.year].mosreg = Math.floor(item.sum);
                } else {
                    data[item._id.month + "." + item._id.year] = {mos: 0, mosreg: Math.floor(item.sum), eat: 0};
                }
            });
        }

        if (props.items.eat !== undefined && props.items.eat.length > 0) {
            props.items.eat.map((item) => {
                if (data[item._id.month + "." + item._id.year] !== undefined) {
                    data[item._id.month + "." + item._id.year].eat = Math.floor(item.sum);
                } else {
                    data[item._id.month + "." + item._id.year] = {mos: 0, mosreg: 0, eat: Math.floor(item.sum)};
                }
            });
        }

        Object.keys(data).map((key) => {
            xaxis.push(key);
            mos.push(data[key].mos);
            mosreg.push(data[key].mosreg);
            eat.push(data[key].eat)
        });


        this.state = {
            options: {
                chart: {
                    id: "basic-bar"
                },
                xaxis: {
                    categories: xaxis.reverse()
                }
            },
            series: [
                {
                    name: "mos.ru",
                    data: mos.reverse()
                },
                {
                    name: "mosreg.ru",
                    data: mosreg.reverse()
                },
                {
                    name: "agregatoreat.ru",
                    data: eat.reverse()
                }
            ]
        };
    }

    render() {
        return (
            <div className="card">
                <div className="card-header">
                    Статистика
                </div>
                <div className="card-body">
                    <div className="mixed-chart">
                        <Chart
                            options={this.state.options}
                            series={this.state.series}
                            type="area"
                            width="100%"
                        />
                    </div>
                </div>
            </div>
        );
    }
}