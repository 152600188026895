import React from "react"
import {SignButton} from "../views/SignButton";
import Axios from "axios";
import {toast} from "react-toastify"

export function NewSignButton(dataUrl) {
    return class extends React.Component {
        constructor(props) {
            super(props);

            this.documentId = props.id;
            /**
             * TODO Перенести получение и сохранение токена в сервисы, для быстрого изменения места хранения
             * @type {string}
             */
            this.token = localStorage.getItem('token');
            this.handleClick = this.handleClick.bind(this);

            this.state = {
                isLoading: false
            };
        }

        handleClick() {

            this.setState({
                isLoading: true,
            });

            const config = {
                headers: {
                    'Authorization': 'bearer ' + this.token,
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            };

            const params = new URLSearchParams();
            params.append('id', this.documentId);

            Axios.post(dataUrl, params, config)
                .then((response) => {
                    this.setState({
                        isLoading: false,
                    });
                    if (response.status === 200) {
                        const result = response.data;
                        if (result.Status === 'ok') {
                            toast.success("Документ подписан", {
                                className: "toast-success"
                            });
                        } else {
                            toast.error(result.Data.Message, {
                                className: "toast-error"
                            });
                        }
                    } else {
                        toast.error("Ошибка сервера!", {
                            className: "toast-error"
                        });
                    }
                })
        };

        render() {
            if (this.state.isLoading) {
                return (
                    <button className="btn btn-sm btn-primary" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" /> Подписываем...
                    </button>
                )
            } else {
                return (
                    <SignButton handle={this.handleClick}/>
                );
            }
        }
    }
}