import React from "react"
import {Link} from "react-router-dom";

export function Forbidden(props) {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="error-template">
                        <h1>Ошибка!</h1>
                        <h2>
                            403 Forbidden</h2>
                        <div className="error-details">
                            У вас нет доступа к этой странице!
                        </div>
                        <div className="error-actions">
                            <Link to="/" className="btn btn-primary btn-sm btn-lg">
                                На главную
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}