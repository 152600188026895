import React from "react"
import {ContentLayout} from "../../layouts/ContentLayout";
import AddKeywordFormContainer from "../../components/containers/AddKeywordFormContainer";

export function AddKeyword() {
    return (
        <ContentLayout title="Добавление ключевого слова">
            <div className="row">
                <div className="col-6 p-0">
                    <br />
                    <AddKeywordFormContainer />
                </div>
            </div>
        </ContentLayout>
    );
}