export const getTenderLink = ({platform, external_id, sub_platform}) => {
    let code = platform?.code ?? platform;

    switch (code) {
        case "eat":
            return `https://agregatoreat.ru/purchases/announcement/${external_id}/info`;
        case "mosru":
            return `https://zakupki.mos.ru/auction/${external_id}`;
        case "mosreg":
            return `https://market.mosreg.ru/Trade/ViewTrade/${external_id}`;
        case "rts-market":
            if (sub_platform && sub_platform.length > 0) {
                return sub_platform + '/Trade/ViewTrade?id=' + external_id;
            } else if (platform.url === 'https://market.rts-tender.ru/') {
                return `https://market.rts-tender.ru/search/sell/${external_id}/request`;
            } else {
                return `${platform.url}/Trade/ViewTrade?id=${external_id}`;
            }
        case "gz-spb":
            return `https://estore.gz-spb.ru/electronicshop/procedure/form/view/${external_id}`;
    }
}
