import React, {useCallback, useEffect, useState} from "react"
import BootstrapTable from "react-bootstrap-table-next";
import Axios from "axios";
import {apiConstants} from "../../constants/ApiConstants";
import {Link} from "react-router-dom";
import {routeConstants} from "../../constants/RouteConstants";
import {useHistory} from "react-router";
/* eslint-disable */
export default function MosSettings() {

  const history = useHistory();
  const [settings, setSettings] = useState();
  const token = localStorage.getItem('token');
  const config = {
    headers: {
      'Authorization': 'bearer ' + token
    }
  };

  useEffect(() => {
    loadData();

  }, []);

  const deleteSettings = (id) => {
    Axios.delete(apiConstants.API_URL + '/settings/'+id, config).then((resp) => {
      loadData();
    })
  }

  const loadData = () => {
    Axios.get(apiConstants.API_URL + '/settings', config).then((resp) => {
      let {status, data} = resp.data;
      if (status === 'success') {
        if (data && data.length > 0) {
          setSettings(data);
        } else {
          history.push(routeConstants.MOS_SETTINGS_ADD);
        }
      }
    });
  }

  const columns = [
    {
      dataField: "Name",
      text: "Название",
      sort: false,
      headerClasses: "text-nowrap",
    },
    {
      dataField: "Login",
      text: "Login",
      sort: false,
      headerClasses: "text-nowrap",
    },
    {
      dataField: "Password",
      text: "Пароль",
      sort: false,
      headerClasses: "text-nowrap",
    },
    {
      dataField: "Time",
      text: "Время",
      sort: false,
      headerClasses: "text-nowrap",
    },
    {
      dataField: "Tax",
      text: "НДС",
      sort: false,
      headerClasses: "text-nowrap",
    },
    {
      dataField: "UserCert",
      text: "Сертификат",
      sort: false,
      headerClasses: "text-nowrap",
    },
    {
      dataField: "action",
      text: "Действия",
      formatter: function (cell, row) {
        return (
          <div className="btn-group btn-group-sm action-group" role="group">
            <Link to={routeConstants.MOS_SETTINGS + "/" + row.ID} type="button" className="btn btn-success edit" title="Редактировать">
              <i className="fa fa-edit" />
            </Link>
            <button onClick={() => deleteSettings(row.ID)} type="button" className="btn btn-danger delete" title="Удалить">
              <i className="fa fa-trash" />
            </button>
          </div>
        )
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    }
  ];

  return (
    settings ?
      <div className="col-12">
        <div className="mb-3 mt-3">
          <h3>Аккаунты Портала поставщиков</h3>
        </div>
          <div className="table-responsive-lg">
            <div className={"row mb-2"}>
              <div className={"col-12"}>
                <Link to={routeConstants.MOS_SETTINGS_ADD} className="btn btn-primary">
                  <i className="fa fa-plus" />
                  <span style={{display: "inline-block", paddingLeft: '5px'}}>Добавить</span>
                </Link>
              </div>
            </div>
            <BootstrapTable
              wrapperClasses="table-responsive"
              bordered={false}
              classes="table table-sm table-light table-striped table-condensed table-responsive-lg"
              bootstrap4
              data={settings}
              columns={columns}
              keyField="ID"
            />
          </div>
      </div> : ''
  );
}