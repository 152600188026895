import React from 'react';
import SignInFormContainer from "../components/containers/SignInFormContainer";

export default function SignIn() {
    return (
        <div className="container-fluid">
            <div className="row">
                <aside className="col-sm-12 col-md-5 col-lg-3 col-xl-3 m-auto pt-5">
                    <div className="card">
                        <SignInFormContainer />
                    </div>
                </aside>
            </div>
        </div>
    );
}